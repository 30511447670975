import apiMerchant from '@/config/apiMerchant.js'

import { QuillEditor } from '@vueup/vue-quill'
import 'quill/dist/quill.snow.css'

export default {
    components: {
        QuillEditor
    },
    
    data(){
        return{
            editorOptions: {
                modules: {
                    toolbar: [
                        [{ header: [2, 3, 4, 5, 6, false] }],
                        // [{ font: [this.fontsDescription] }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        // ['blockquote', 'code-block'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        // [{ script: 'sub' }, { script: 'super' }],
                        // [{ indent: '-1' }, { indent: '+1' }],
                        // [{ direction: 'rtl' }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ]
                },
                
                placeholder: 'Descrição completa do produto...',
            },
            
            // fontsDescription: [
            //     { value: 'sans-serif', text: 'Sans Serif' },
            //     { value: 'monospace', text: 'Monospace' },
            //     { value: 'serif', text: 'Serif' },
            //     { value: 'Poppins Regular', text: 'Poppins Regular' }
            // ],
            
            load_step01: true,

            // BRANDS
            dataBrands: [],

            flowBrand: {
                search: "",
                results: [],
                add: false
            },

            // CATEGORIES
            dataCategories: [
                {
                    id: "1",
                    name: "Notebooks",
                    children: [
                        { id: "11", name: "Usados", children: [] },
                        { id: "12", name: "Gamer", children: [] }
                    ]
                },
                {
                    id: "2",
                    name: "Calças",
                    children: [
                        {
                            id: "21",
                            name: "Masculinas",
                            children: [
                                { id: "211", name: "Calças Jeans", children: [] },
                                { id: "212", name: "Sarja", children: [] }
                            ]
                        },
                        {
                            id: "31",
                            name: "Femininas",
                            children: [
                                { id: "311", name: "Calças Jeans", children: [] },
                                { id: "312", name: "Sarja", children: [] }
                            ]
                        }
                    ]
                },
                {
                    id: "3",
                    name: "Celulares",
                    children: [
                        { 
                            id: "53b4df6f-65d7-4c45-8078-b54783356aff",
                            name: "Smartphones",
                            children: [
                                { id: "311", name: "Flip", children: [] },
                                { id: "312", name: "Anti alguma coisa", children: [] }
                            ]
                        },
                        { id: "32", name: "Celulares Básicos", children: [] }
                    ]
                }
            ],
    
            flowCategory: {
                search: "",
                results: [],
                add: {
                    show: false,
                    selectedCategory: null,
                    sortedCategories: []
                }
            }
        }
    },

    async mounted(){

        // BRANDS
        await apiMerchant("/api/v2/market/brand")
        .then(response => {
            this.dataBrands = response.data

            setTimeout(() => {
                this.load_step01 = false
            }, 100);
        })
        .catch(error => {
            console.log(error)

            this.$toast.error("Houve um erro ao carregar as marcas")
        })
    },

    methods: {
        generateSlugProduct(value, place, idVariant) {
            const name = value.toLowerCase();
            const slug = name.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^\w\s-]/g, "-")
            .replace(/\s+/g, "-")
            .replace(/--+/g, "-");

            if ( place == "product" ){
                return this.dataProduct.slug = slug;
            }

            if ( place == 'variant' ){
                return this.dataProduct.variants.variant.filter(variant => variant.id == idVariant)[0].id
            }
        },

        
        // BRAND
        filterBrands(){
            const searchTerm = this.flowBrand.search.toLowerCase();

            if (!searchTerm) {
                this.flowBrand.results = [];
                return;
            }

            const startsWithTerm = this.dataBrands.filter(brand =>
                brand.name.toLowerCase().startsWith(searchTerm)
            );
            
            const includesTerm = this.dataBrands.filter(brand =>
                brand.name.toLowerCase().includes(searchTerm) &&
                !brand.name.toLowerCase().startsWith(searchTerm)
            );
            
            this.flowBrand.results = [...startsWithTerm, ...includesTerm];
        },

        addBrand(){
            // this.dataProduct.brand = this.flowBrand.search
            this.dataProduct.brand_id = "00000000-0000-0000-0000-000000000000"

            this.flowBrand.add = false
            this.flowBrand.search = ""
            this.flowBrand.results = ""
        },

        selectBrand(value){
            // this.dataProduct.brand = value.name
            this.dataProduct.brand_id = value.id

            this.flowBrand.search = ""
            this.flowBrand.results = ""
        },


        // CATEGORIES
        filterCategories() {
            const searchTerm = this.flowCategory.search.toLowerCase();

            if (!searchTerm) {
                this.flowCategory.results = [];
                return;
            }

            this.flowCategory.results = this.dataCategories
            .map(category => {
                const categoryMatches = category.name.toLowerCase().includes(searchTerm);

                const filteredChildren = category.children
                    .map(subcategory => {
                        const subcategoryMatches = subcategory.name.toLowerCase().includes(searchTerm);

                        const filteredSubChildren = subcategory.children.filter(subSubcategory =>
                            subSubcategory.name.toLowerCase().includes(searchTerm)
                        );

                        if (subcategoryMatches || filteredSubChildren.length || categoryMatches) {
                            return { ...subcategory, children: filteredSubChildren.length > 0 || subcategoryMatches ? subcategory.children : filteredSubChildren };
                        }

                        return null;
                    })
                    .filter(Boolean);

                if (categoryMatches || filteredChildren.length) {
                    return { ...category, children: filteredChildren.length > 0 || categoryMatches ? category.children : filteredChildren };
                }

                return null;
            })
            .filter(Boolean);
        },


        categoryName(categories, id) {
            for (let category of categories) {
                if (category.id === id) {
                    return category;
                }
                if (category.children.length) {
                    const found = this.categoryName(category.children, id);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        },


        // NEW CATEGORY
        generateCategoryPaths(categories, parentPath = "") {
            let categoryPaths = [];
    
            categories.forEach(category => {
                const fullPath = parentPath ? `${parentPath} / ${category.name}` : category.name;
    
                if (!category.children || category.children.length === 0) {
                    categoryPaths.push({ id: category.id, fullPath });
                } else {
                    categoryPaths.push(...this.generateCategoryPaths(category.children, fullPath));
                }
            });
    
            return categoryPaths;
        },

        sortCategories() {
            const allCategories = this.generateCategoryPaths(this.dataCategories);
            
            this.flowCategory.add.sortedCategories = allCategories.sort((a, b) => a.fullPath.localeCompare(b.fullPath));
        },

        selectCategory(id) {
            this.dataProduct.category_id = id;
            // this.dataProduct.category = name;

            this.flowCategory.search = ""
            this.flowCategory.results = ""
        },

        addCategory() {
            // this.dataProduct.category = this.flowCategory.search;
            this.dataProduct.category_id = this.flowCategory.add.selectedCategory;

            this.flowCategory.add.show = false;

            this.flowCategory.search = ""
            this.flowCategory.add.newCategoryName = '';
            this.flowCategory.add.selectedCategory = null;
        },



        // VALIDATE FORM
        validateStep01() {
            this.flowRegister.validate.name = this.dataProduct.name ? true : false;
            this.flowRegister.validate.slug = this.dataProduct.slug ? true : false;
            // this.flowRegister.validate.ean = this.dataProduct.ean ? true : false;
            // this.flowRegister.validate.sku = this.dataProduct.sku ? true : false;
            this.flowRegister.validate.description = this.dataProduct.description ? true : false;
            this.flowRegister.validate.brand_id = this.dataProduct.brand_id ? true : false;
            this.flowRegister.validate.product_type_id = this.dataProduct.product_type_id ? true : false;
            this.flowRegister.validate.category_id = this.dataProduct.category_id ? true : false;
            this.flowRegister.validate.asset_id = this.dataProduct.asset_id ? true : false;
        },

        removeError(place, field) {
            if ( place == "product" ){
                if (this.dataProduct[field]) {
                  this.flowRegister.validate[field] = true;
                } else {
                  this.flowRegister.validate[field] = false;
                }
            }
            
            if ( place == "variant" ){
                if (this.modalVariant.data[field]) {
                  this.flowRegisterVariant.validate[field] = true;
                } else {
                  this.flowRegisterVariant.validate[field] = false;
                }
            }
        }
    },
}