<template>
    <div class="attachments-container">
         <div class="upload-container">
            <div 
                class="drop-area" 
                @dragover.prevent="onDragOver" 
                @drop.prevent="onDrop" 
                @dragleave="onDragLeave"
                @dragenter="onDragEnter">

                <p v-if="!flowUpload.images.length">Arraste suas imagens aqui ou clique para selecionar</p>
                <p v-else>{{ flowUpload.images.length }} imagem(ns) selecionada(s)</p>

                <input type="file" multiple @change="onFileChange" ref="fileInput" />
            </div>

            <!-- Lista de pré-visualizações -->
            <div v-if="flowUpload.images.length" class="image-preview">
                <div v-for="(image, index) in flowUpload.images" :key="index" class="image-preview-item">
                    <img :src="image.preview" :alt="'Image ' + (index + 1)" />
                    <div class="remove" @click="removeImage(index)"></div>
                </div>
            </div>

            <div class="btn-center">
                <div class="btn-primary btn-disabled" v-if="!flowUpload.images.length">Fazer Upload</div>
                <div class="btn-primary" v-else @click="uploadImages">Fazer Upload</div>
            </div>
        </div>


        <div class="attachments">
            <h3 class="title-secondary">Galeria de imagens</h3>
            <div class="anc" id="anchor_attachments"></div>

            <div class="gallery">
                <figure class="attachment"
                    v-for="(gallery, index) in dataImages"
                    :key="index"
                    @click="modalAttachment(gallery.id)">

                    <img :src="`/images/items/${gallery.attachment}`">

                    <h4>
                        <span>
                            {{ gallery.legend }}
                        </span>
                    </h4>
                </figure>
            </div>
        </div>
    </div>


    <!-- LOAD UPLOAD -->
    <div class="load-upload" v-if="flowUpload.load">
        <div class="load"></div>
        <strong>Uploading...</strong>
    </div>


    <!-- MODAL ATTACHMENT -->
    <div class="modal modal-attachment" v-if="modal.show">
        <div class="modal-cont">
            <div class="modal-close" @click="modal.show = false"></div>

            <div class="modal-scroll">
                <div class="select-variants">
                    <div class="attachment">
                        <img src="/images/items/iphone-13.jpg">

                        <label>
                            Legenda da imagem
                            <input type="text" class="form" value="Ecrã + Câmera">
                        </label>
                    </div>

                    <div class="variants">
                        <h3 class="title-secondary">Variantes</h3>

                        <div class="header-variants">
                            <p>Selecione a(s) variante(s) referente(s) a esta imagem.</p>

                            <div class="form-group-check check-all">
                                <input type="checkbox" class="form-check"
                                    id="check-all"
                                    v-model="checkAll"
                                    @change="toggleAll">

                                <label for="check-all" class="form-check-label">Marcar/Desmarcar todas as variantes</label>
                            </div>
                        </div>

                        <div class="form-group-check" v-for="(variant, index) in variants" :key="index">
                            <input type="checkbox" class="form-check"
                                :id="variant.id"
                                :value="variant.id"
                                v-model="selectedVariants">
                            
                            <label :for="variant.id" class="form-check-label">
                                <h3>{{variant.variant_1}} {{variant.variant_2}}</h3>
                            </label>
                        </div>


                        <div class="btn-center">
                            <div class="load" v-if="modal.load"></div>
                            <div class="btn-primary" v-if="!modal.load" @click="saveModalAttachment()">Salvar</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            variants: {
                type: Array
            }
        },

        data() {
            return {
                
                flowUpload: {
                    isDragging: false, // Controla o estado de drag
                    images: [], // Armazena as imagens selecionadas
                    load: false
                },

                dataImages: [
                    { id: "111", attachment: "iphone-13.jpg", legend: "Ecrâ + Câmera"},
                    { id: "112", attachment: "iphone-13.jpg", legend: "Ecrâ + Câmera"},
                    { id: "113", attachment: "iphone-13.jpg", legend: "Ecrâ + Câmera"},
                    { id: "114", attachment: "iphone-13.jpg", legend: "Ecrâ + Câmera"},
                    { id: "115", attachment: "iphone-13.jpg", legend: "Ecrâ + Câmera"},
                    { id: "116", attachment: "iphone-13.jpg", legend: "Ecrâ + Câmera"},
                    { id: "117", attachment: "iphone-13.jpg", legend: "Ecrâ + Câmera"},
                    { id: "118", attachment: "iphone-13.jpg", legend: "Ecrâ + Câmera"}
                ],

                modal: {
                    show: false,
                    load: false,
                    data: {}
                },

                selectedVariants: [],
                checkAll: false,
            };
        },
        
        methods: {
            onDragOver() {
                this.flowUpload.isDragging = true;
            },

            onDragLeave() {
                this.flowUpload.isDragging = false;
            },
            
            onDragEnter() {
                this.flowUpload.isDragging = true;
            },

            onDrop(event) {
                this.flowUpload.isDragging = false;
                const files = Array.from(event.dataTransfer.files);
                this.handleFiles(files);
            },

            onFileChange(event) {
                const files = Array.from(event.target.files);
                this.handleFiles(files);
            },

            handleFiles(files) {
                files.forEach((file) => {
                    if (file.type.startsWith('image/')) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.flowUpload.images.push({
                            file: file,
                            preview: e.target.result,
                            });
                        };
                        reader.readAsDataURL(file);
                    }
                });
            },

            removeImage(index) {
                this.flowUpload.images.splice(index, 1);
            },

            uploadImages() {
                // Aqui você pode implementar a lógica de envio das imagens para o servidor
                console.log("Upload de imagens:", this.flowUpload.images);
                
                this.flowUpload.load = true

                setTimeout(() => {
                    this.flowUpload.load = false
                    this.$toast.success("Upload concluído")

                    document.getElementById('anchor_attachments').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });

                }, 1500);
            },


            modalAttachment(id){
                console.log(id)
                this.modal.show = true
            },

            toggleAll() {
                if (this.checkAll) {
                    this.selectedVariants = this.variants.map(variant => variant.id);
                } else {
                    this.selectedVariants = [];
                }
            },

            saveModalAttachment(){
                this.modal.load = true
                
                setTimeout(() => {
                    this.modal.load = false
                    this.modal.show = false
                    this.$toast.success("Imagens associadas com sucesso!")
                }, 1000);
            }
        },

        watch: {
            selectedVariants() {
                this.checkAll = this.selectedVariants.length === this.variants.length;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';
    @import '@/assets/scss/_modal.scss';

    .attachments-container{
        .upload-container {
            margin-bottom: 3rem;
            padding-bottom: 3rem;
            border-bottom: 2px solid #ddd;

            .drop-area {
                position: relative;
                border: 2px dashed #ccc;
                background: #eee;
                border-radius: 12px;
                padding: 2rem 1rem;
                margin-bottom: 2rem;
                cursor: pointer;

                &.dragging {
                    background-color: #f0f8ff;
                }

                p{
                    text-align: center;
                    margin-bottom: 0;
                }

                input[type="file"] {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }

            .image-preview {
                display: flex;
                flex-wrap: wrap;
                background: #f5f5f5;
                border-radius: 12px;
                padding: .5rem;
                margin-bottom: 2rem;
                
                .image-preview-item {
                    position: relative;
                    padding: 5px;
                    margin: 5px;
                    background: $color-text-tertiary;
                    border: 1px solid #ccc;
                    border-radius: 6px;

                    img{
                        object-fit: cover;
                        border-radius: 6px;

                        @media (max-width: 992px){
                            width: 125px;
                            height: 125px;
                        }
                        
                        @media (min-width: 992px){
                            width: 160px;
                            height: 160px;
                        }
                    }

                    .remove{
                        position: absolute;
                        top: -5px;
                        right: -5px;
                        width: 30px;
                        height: 30px;
                        border-radius: 6px;
                        background: url('~@/assets/images/icons/trash-white.svg') no-repeat center $color-pending;
                        background-size: 65% auto;
                        box-shadow: -4px 4px 6px 0px rgba(0, 0, 0, 0.2);

                        @media (min-width: 992px){
                            transition: .3s;
                            cursor: pointer;

                            &:hover{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }

            .btn-center{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .attachments{
            .gallery{
                display: flex;
                flex-wrap: wrap;

                .attachment{
                    @include shadown;
                    border-radius: 6px;
                    background: $color-text-tertiary;

                    @media (max-width: 992px){
                        padding: 5px;
                        margin: 5px;
                    }

                    @media (min-width: 992px){
                        padding: 5px;
                        margin: 10px;
                        transition: .3s;
                        cursor: pointer;

                        &:hover{
                            transform: scale(1.05);
                        }
                    }

                    img{
                        object-fit: cover;
                        border-radius: 6px;
                        margin-bottom: .5rem;

                        @media (max-width: 992px){
                            width: 135px;
                            height: 135px;
                        }
                        
                        @media (min-width: 992px){
                            width: 190px;
                            height: 190px;
                        }
                    }

                    h4{
                        margin: 0;
                        background: #f5f5f5;
                        border-radius: 6px;
                        
                        span{
                            display: block;
                            text-align: center;
                            padding: 0 .5rem;
                            overflow: hidden;

                            @media (max-width: 576px){
                                font-size: 12px;
                                line-height: 24px;
                            }
                            
                            @media (min-width: 576px){
                                font-size: 14px;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }


    // LOAD UPLOAD
    .load-upload{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 9999;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba($color: $color-text-tertiary, $alpha: 0.9);

        strong{
            color: $color-secondary;
        }
    }


    // MODAL ATTACHMENT
    .modal-attachment{
        .modal-cont{
            background: #eee;
            border-color: #eee;
            padding: 15px 5px 15px 15px;

            .modal-scroll{
                .select-variants{
                    display: flex;
    
                    @media (max-width: 992px){
                        flex-direction: column;
                        align-items: center;
                    }
    
                    @media (min-width: 992px){
                        justify-content: space-between;
                    }
    
                    .attachment{
                        @media (max-width: 992px){
                            max-width: 350px;
                            margin-bottom: 3rem;
                        }
    
                        @media (min-width: 992px){
                            max-width: 400px;
                            
                        }
    
                        img{
                            @include shadown;
                            border-radius: 12px;
                            margin-bottom: 1rem;
                            border: 1px solid #ddd;
                        }
    
                        label{
                            background: #f5f5f5;
                            padding: 1rem;
                            border-radius: 12px;
                            font-size: 13px;
                        }
                    }
    
                    .variants{
                        background: $color-text-tertiary;
                        padding: 1rem;
                        border-radius: 12px;
    
                        @media (max-width: 992px){
                            margin-bottom: 2rem;
                        }
                        
                        @media (min-width: 992px){
                            margin-left: 2rem;
                        }

                        .header-variants{
                            background: #f5f5f5;
                            border-radius: 12px;
                            padding: 1rem;
                            margin-bottom: 1.5rem;

                            p{
                                color: #777;
                                margin-bottom: 1rem;
                            }
                        }
                        
    
                        .form-group-check{
                            &.check-all{
                                .form-check-label{
                                    font-family: $font-primary-semibold;
                                    // padding: 8px 8px 8px 38px;

                                    // &:before{
                                    //     top: 8px;
                                    //     left: 8px;
                                    // }
                                }
                            }

                            &:not(&.check-all){
                                .form-check-label{
                                    @include shadown;
                                    padding: 8px 8px 8px 38px;
                                    margin-bottom: .5rem;
                                    width: 100%;
                                    min-height: 38px;
                                    border-radius: 6px;
                                    background: $color-text-tertiary;
                                    border: 1px solid #f5f5f5;
        
                                    &:before{
                                        top: 8px;
                                        left: 8px;
                                    }
        
                                    h3{
                                        margin: 0;
                                        color: #666;
        
                                        @media (max-width: 992px){
                                            font-size: 13px;
                                            line-height: 15px;
                                        }
                                        
                                        @media (min-width: 992px){
                                            font-size: 16px;
                                            line-height: 18px;
                                        }
                                    }
                                }
        
                                .form-check{
                                    &:checked{
                                        +label{
                                            background: #f0fff2;
                                            border-color: rgba($color: $color-secondary, $alpha: 0.1);
                                            
                                            h3{
                                                color: $color-primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        .btn-center{
                            display: flex;
                            justify-content: center;
                            padding: 2rem 0;
    
                            .load{
                                margin: 0;
                                height: 70px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>